import React from 'react';
import PropTypes from 'prop-types';

import {
    Plus,
    Layout,
    ProjectsGrid,
    TitleAnimation,
    ScaleAndFadeAnimation,
} from '../../components';
import { useTranslations, useReady } from '../../hooks';

import styles from './Projects.module.css';

export const Projects = ({
    location,
    data: {
        contentful: {
            projectsPageCollection: {
                items: [page],
            },
            projectCollection: {
                items: projects,
            },
        },
    },
}) => {
    const isReady = useReady();
    const { completedProjects } = useTranslations();

    const {
        title,
        tabTitle,
        description,
        projectsNumber,
    } = page;

    const seoData = { title: `${tabTitle} - Agostinho & João Pinto, Lda` };

    return (
        <Layout
            seoData={ seoData }
            location={ location }
            className={ styles.main }>
            <h1 className={ styles.heading }>{ tabTitle }</h1>
            <section className={ styles.hero }>
                <TitleAnimation
                    className={ styles.title }
                    startAnimation={ isReady }>
                    <h2>{ title }</h2>
                </TitleAnimation>
                <ScaleAndFadeAnimation
                    startAnimation={ isReady }
                    className={ styles.infoContainer }>
                    <div className={ styles.info }>
                        <p className={ styles.projects }>
                            { projectsNumber }
                            <span className={ styles.completedProjects }>
                                <Plus className={ styles.plus } />
                                { completedProjects }
                            </span>
                        </p>
                        <p className={ styles.description }>{ description }</p>
                    </div>
                </ScaleAndFadeAnimation>
            </section>
            <section className={ styles.projectsSection }>
                <ProjectsGrid projects={ projects } />
            </section>
        </Layout>
    );
};

Projects.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            projectsPageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
            projectCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Projects;
